import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";

import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("../common/ImageLoader"));

import showcaseImg01 from "assets/images/landingAir/mila-showcase/mila-room-showcase-01.jpg";
import showcaseImg01Webp from "assets/images/landingAir/mila-showcase/mila-room-showcase-01.jpg?as=webp";
import showcaseImg02 from "assets/images/landingAir/mila-showcase/mila-room-showcase-02.jpg";
import showcaseImg02Webp from "assets/images/landingAir/mila-showcase/mila-room-showcase-02.jpg?as=webp";
import showcaseImg03 from "assets/images/landingAir/mila-showcase/mila-room-showcase-03.jpg";
import showcaseImg03Webp from "assets/images/landingAir/mila-showcase/mila-room-showcase-03.jpg?as=webp";

import showcaseImg01Mobile from "assets/images/landingAir/mila-showcase/mila-room-showcase-sm-01.jpg";
import showcaseImg01MobileWebp from "assets/images/landingAir/mila-showcase/mila-room-showcase-sm-01.jpg?as=webp";
import showcaseImg02Mobile from "assets/images/landingAir/mila-showcase/mila-room-showcase-sm-02.jpg";
import showcaseImg02MobileWebp from "assets/images/landingAir/mila-showcase/mila-room-showcase-sm-02.jpg?as=webp";
import showcaseImg03Mobile from "assets/images/landingAir/mila-showcase/mila-room-showcase-sm-03.jpg";
import showcaseImg03MobileWebp from "assets/images/landingAir/mila-showcase/mila-room-showcase-sm-03.jpg?as=webp";

/**

/**
 * @returns {JSX.Element}
 * @constructor
 */

const slideData = [
  {
    image: showcaseImg01,
    imageWebp: showcaseImg01Webp,
    smallImage: showcaseImg01Mobile,
    smallWebp: showcaseImg01MobileWebp,
    title: "Bedroom",
  },
  {
    image: showcaseImg02,
    imageWebp: showcaseImg02Webp,
    smallImage: showcaseImg02Mobile,
    smallWebp: showcaseImg02MobileWebp,
    title: "Living Room",
  },
  {
    image: showcaseImg03,
    imageWebp: showcaseImg03Webp,
    smallImage: showcaseImg03Mobile,
    smallWebp: showcaseImg03MobileWebp,
    title: "Kitchen",
  },
];
const MilaShowcase = () => {
  const [isSliderInitialized, setIsSliderInitialized] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const handleBeforeChange = (index) => {
    setCurrentSlide(index);
  };

  const clickHandler = (index) => {
    sliderRef?.current.slickGoTo(index);
  };

  useEffect(() => {
    if (!isSliderInitialized) return;
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 100 : prev + 1));
    }, 60);
    return () => clearInterval(interval);
  }, [isSliderInitialized, currentSlide]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    fade: true,
    onInit: () => {
      setIsSliderInitialized(true);
    },
    beforeChange: (_, nextIdx) => {
      handleBeforeChange(nextIdx);
    },
  };

  return (
    <section className="mila-showcase" style={{ "--progress": `${progress}%` }}>
      <div className="mila-showcase__card">
        <div className="mila-showcase__content">
          <div className="mila-showcase__header">
            <h2 className="mila-showcase__title">
              She’s beautifully crafted <br />
              and looks great in every room.
            </h2>
            <div className="mila-showcase__list-wrapper">
              <div className="mila-showcase__list-overflow">
                <ul className="mila-showcase__list">
                  {slideData.map((slide, index) => (
                    <li
                      key={index}
                      className={currentSlide === index ? "active" : ""}
                      onClick={() => clickHandler(index)}
                    >
                      <strong>{slide.title}</strong>
                      <span className="mila-showcase__progressbar">
                        <span></span>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Slider className="mila-showcase__slider" {...settings} ref={sliderRef}>
          {slideData.map((slide, index) => (
            <div
              key={index}
              className={`mila-showcase__slide ${
                slideData.length - 1 === index ? "last-slide" : ""
              }`}
            >
              <ImageLoader
                className="mila-showcase__img img-relative"
                placeholderAspectRatio={1400 / 860}
                image={slide.image}
                webp={slide.imageWebp}
                smallImage={slide.smallImage}
                smallWebp={slide.smallWebp}
                alt={slide.title}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default MilaShowcase;
